<template>
  <LazyDrawer position="right" :is-open="branchTray" :hide-scroll="false">
    <template #header>
      <Stack
        tag="div"
        :justify="currentStep !== Steps.details && currentStep !== Steps.search ? 'between' : 'end'"
        align="start"
      >
        <button
          data-test-id="branchTrayBackButton"
          @click="navigateBack()"
          v-show="currentStep !== Steps.details && currentStep !== Steps.search"
        >
          <Icon name="nav-left" :size="16" filled class="text-charcoal-default" />
          <span class="sr-only">Navigate back to search</span>
        </button>
        <button
          data-test-id="closeBranchTrayButton"
          @click="closeTray()"
          class="text-charcoal-default justify-self-end"
        >
          <Icon name="plus-thick" :size="16" filled class="text-charcoal-default rotate-45" />
          <span class="sr-only">CloseBranchTray</span>
        </button>
      </Stack>
      <Heading tag="h3" size="xs" class="mt-xs">
        {{ branchTrayTitle ? `Change ${branchTrayTitle} branch` : headingText }}
      </Heading>
      <Banner v-if="branchTrayTitle === 'delivery'" variant="warning" class="mt-xs">
        Changing your delivery branch might affect the availability and price of products in your basket.
      </Banner>
      <Banner v-else-if="branchTrayTitle === 'collection'" variant="warning" class="mt-xs">
        Changing your collection branch might affect the availability and price of products in your basket.
      </Banner>
    </template>

    <aside class="pt-md">
      <BranchTrayDetails
        :branch="selectedBranch"
        v-if="selectedBranch"
        class="px-sm"
        v-show="currentStep === steps.details"
        @update:step="(step: Steps) => updateSteps(step)"
      />
      <BranchTraySearch class="px-sm" v-show="currentStep === Steps.search" />
      <BranchTrayNearest v-show="currentStep === Steps.nearest" />
      <BranchTrayDetails :branch="selectedTrayBranch" class="px-sm" v-if="currentStep === Steps.selected" />
    </aside>
  </LazyDrawer>
</template>

<script lang="ts" setup>
import { steps, Steps } from "@/composables/useBranchTray/branchTrayTypes";

const { branchTray, setBranchTray, overlay, branchPostcode, branchTrayTitle } = useUIState();
const { selectedBranch } = useBranches();
const { currentStep, updateSteps, selectedTrayBranch } = useBranchTray();

const headingText = computed(() => {
  if (currentStep.value === Steps.details && selectedBranch.value) {
    return selectedBranch.value.name;
  }

  if (currentStep.value === Steps.selected && selectedTrayBranch.value?.name) {
    return selectedTrayBranch.value.name;
  }

  return "Find your nearest branch";
});

const navigateBack = () => {
  if (currentStep.value === Steps.nearest) {
    updateSteps(Steps.search);
  }

  if (currentStep.value === Steps.selected) {
    updateSteps(Steps.nearest);
  }
};

const closeTray = () => {
  setBranchTray();
  if (selectedBranch.value) {
    updateSteps(Steps.details);
  } else {
    updateSteps(Steps.search);
  }
};

onMounted(() => {
  if (selectedBranch.value && !branchPostcode.value) {
    updateSteps(Steps.details);
  } else {
    updateSteps(Steps.search);
  }
});

watch(
  selectedBranch,
  () => {
    if (selectedBranch.value && !branchPostcode.value) {
      updateSteps(Steps.details);
    } else {
      updateSteps(Steps.search);
    }
  },
  { immediate: true },
);

watch(
  [overlay],
  () => {
    if (!overlay.value) {
      if (selectedBranch.value) {
        updateSteps(Steps.details);
      } else {
        updateSteps(Steps.search);
      }
    }
  },
  { immediate: true },
);
</script>
